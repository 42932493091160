import React from "react"
// import cx from 'classnames';
import Img from "components/Img"
import { useStaticImages } from "hooks/queryImages"

const AboutColumnImage = ({
  className,
  useImg,
  minHeight,
  src,
  alt,
  children,
  onClickImage,
  ...others
}) => {
  const data = useStaticImages()

  if (!src) return null

  const dataImage = data[src].childImageSharp.fluid.src
  const style = !useImg
    ? {
        minHeight,
        backgroundImage: `url(${dataImage})`,
      }
    : {}

  return (
    <div
      {...others}
      onClick={onClickImage}
      onKeyDown={onClickImage}
      className={`column-image ${className}`}
      style={style}
      role="button"
      tabIndex={0}
    >
      {useImg && (
        <div className="column-image--image">
          <Img
            isFluid={true}
            name={src}
            alt={alt}
          />
        </div>
      )}
      <div className="column-image--text">{children}</div>
    </div>
  )
}

AboutColumnImage.defaultProps = {
  className: "",
  src: undefined,
  text: "",
  alt: "",
  minHeight: 0,
  useImg: false,
}

export default AboutColumnImage
