import React, { useState, useEffect } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { trimAll } from "utils/utils"
import { motion, AnimatePresence } from "framer-motion"
import { fadeVariants } from "utils/motion.utils"
import GSpinner from "components/GSpinner"

const reactBinaryAxios = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "*",
    // withCredentials: true,
  },
})

const FormFocusContact = ({ className, pageData, ...props }) => {
  // const [isLoading, setLoading] = useState(false);
  const { focusPoint, formContact } = pageData;
  const [isSent, setSent] = useState(false)
  const { register, handleSubmit, formState, watch, reset } = useForm({
    mode: "onBlur",
  })
  const watchData = watch(["email", "fullName", "message"])

  const { isDirty, isSubmitting } = formState

  const onSubmit = data => {
    const API = `https://bettytranconsultants.com/mailer/contact.php`

    const formFileData = new FormData()
    formFileData.append("fullName", data.fullName)
    formFileData.append("email", data.email)
    formFileData.append("message", data.message)

    formFileData.append(
      "categories.lifestyle",
      data.categories.lifestyle.toString()
    )
    formFileData.append(
      "categories.fashion",
      data.categories.fashion.toString()
    )
    formFileData.append("categories.retail", data.categories.retail.toString())
    formFileData.append(
      "categories.realEstate",
      data.categories.realEstate.toString()
    )
    formFileData.append(
      "categories.technology",
      data.categories.technology.toString()
    )

    reactBinaryAxios({
      url: API,
      method: "post",
      data: formFileData,
    })
      .then(function (response) {
        reset()
        setSent(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    const shouldRemove = Boolean(
      isSent &&
        (trimAll(watchData.fullName) !== "" ||
          trimAll(watchData.email) !== "" ||
          trimAll(watchData.message) !== "")
    )

    if (shouldRemove) {
      setSent(false)
    }
  }, [watchData, isSent])

  useEffect(() => {
    if (isSent) {
      setTimeout(() => setSent(false), 5000)
    }
  }, [isSent])

  return (
    <div className="form-body form-about-contact">
      <AnimatePresence>
        {isSubmitting && (
          <motion.div
            initial="hidden"
            variants={fadeVariants}
            animate={"visible"}
            exit="hidden"
            className={`wrapper--gspinner`}
          >
            <GSpinner className="spinner" />
          </motion.div>
        )}
      </AnimatePresence>
      <form method={"POST"} onSubmit={handleSubmit(onSubmit)}>
        <AnimatePresence>
          {isSent && (
            <motion.div
              variants={fadeVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="notification is-info is-contact"
            >
              {formContact.sentSuccess}
            </motion.div>
          )}
        </AnimatePresence>
        <div className="field is-horizontal">
          <div className="field-label">
            <label htmlFor="contact--full-name" className="label">
              {formContact.fieldFullname}
            </label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    aria-label="Full Name"
                    id="contact--full-name"
                    className="input"
                    type="text"
                    placeholder=""
                    name="fullName"
                    ref={register({ required: true, maxLength: 128 })}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label">
            <label htmlFor="contact--email" className="label">
              {formContact.fieldEmail} <span className="color--red">*</span>
            </label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    aria-label="email"
                    id="contact--email"
                    className="input"
                    type="email"
                    placeholder=""
                    name="email"
                    ref={register({ required: true, maxLength: 128 })}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label">
            {
              //eslint-disabled-next-line
            }
            <label htmlFor="contact--categories" className="label">
              {formContact.fieldCategories} <span className="color--red">*</span>
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control control--checkbox-group">
                <label className="checkbox">
                  <input
                    aria-label={focusPoint.lifeStyle}
                    type="checkbox"
                    name="categories.lifestyle"
                    ref={register({ required: false })}
                  />
                  {focusPoint.lifeStyle}
                </label>
                <label className="checkbox">
                  <input
                    aria-label={focusPoint.fashion}
                    type="checkbox"
                    name="categories.fashion"
                    ref={register({ required: false })}
                  />
                  {focusPoint.fashion}
                </label>
                <label className="checkbox">
                  <input
                    aria-label={focusPoint.retail}
                    type="checkbox"
                    name="categories.retail"
                    ref={register({ required: false })}
                  />
                  {focusPoint.retail}
                </label>
                <label className="checkbox">
                  <input
                    aria-label={focusPoint.realEstate}
                    type="checkbox"
                    name="categories.realEstate"
                    ref={register({ required: false })}
                  />
                  {focusPoint.realEstate}
                </label>
                <label className="checkbox">
                  <input
                    aria-label={focusPoint.technology}
                    type="checkbox"
                    name="categories.technology"
                    ref={register({ required: false })}
                  />
                  {focusPoint.technology}
                </label>
              </div>
              <div className="required-text">* {formContact.fieldRequired}</div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label htmlFor="contact--other-questions" className="label">
              {formContact.fieldOther}
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <textarea
                  id="contact--other-questions"
                  aria-label="Other Questions"
                  className="textarea"
                  placeholder=""
                  name="message"
                  ref={register({ required: false })}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="field field-submit has-text-centered">
          <button
            disabled={!isDirty || isSubmitting}
            type="submit"
            className="btn--send btn--black"
          >
            {formContact.btnSend}
          </button>
        </div>
      </form>
    </div>
  )
}

FormFocusContact.defaultProps = {
  className: "",
}

export default FormFocusContact
