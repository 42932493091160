import React, { useState } from "react"
import Rodal from "rodal"
import SEO from "components/seo"
import Heading from "components/Heading"
import AboutColumnImage from "components/AboutColumnImage"
import HeroImageFull from "components/HeroImageFull";
import FormFocusContact from 'components/FormFocusContact';
import Interweave from "interweave"

const ViewFocus = ({ pageData }) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const {
    page,
    // sitename,
    heading,
    description,
    focusPoint,
    formContact,
  } = pageData;

  const onClickHandler = () => {
    setModalVisible(true)
  }

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--focus--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="focusHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="black is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>

      <div className="max-container wrapper--focus--block-services">
        <div className="container">
          <Heading className="align-center use-med">
            {description}
          </Heading>
          <div className="columns services--container">
            <div className="col-left">
              <div className="column-vertical--wrapper">
                <div className="col-top">
                  <AboutColumnImage
                    onClickImage={onClickHandler}
                    src={"aboutServiceImage1"}
                    alt={focusPoint.lifeStyle}
                    useImg={true}
                  >
                    {focusPoint.lifeStyle}
                  </AboutColumnImage>
                </div>
                <div className="col-bottom">
                  <AboutColumnImage
                    onClickImage={onClickHandler}
                    src={"aboutServiceImage2"}
                    alt={focusPoint.realEstate}
                    className="is-black"
                    useImg={true}
                  >
                    {focusPoint.realEstate}
                  </AboutColumnImage>
                </div>
              </div>
            </div>
            <div className="col-mid">
              <AboutColumnImage
                onClickImage={onClickHandler}
                useImg={true}
                src={"aboutServiceImage3"}
                alt={focusPoint.fashion}
              >
                {focusPoint.fashion}
              </AboutColumnImage>
            </div>
            <div className="col-right">
              <div className="column-vertical--wrapper">
                <div className="col-top">
                  <AboutColumnImage
                    onClickImage={onClickHandler}
                    src={"aboutServiceImage4"}
                    alt={focusPoint.retail}
                    useImg={true}
                  >
                    {focusPoint.retail}
                  </AboutColumnImage>
                </div>
                <div className="col-bottom">
                  <AboutColumnImage
                    onClickImage={onClickHandler}
                    src={"aboutServiceImage5"}
                    alt={focusPoint.technology}
                    useImg={true}
                  >
                    {focusPoint.technology}
                  </AboutColumnImage>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Rodal
        closeOnEsc={true}
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
        animation={"slideUp"}
        customStyles={{
          height: "auto",
          width: "96%",
        }}
        className="btc-modal--root"
      >
        <div className="btc-modal--content">
          <div className="form-header">
            <h4>
              <Interweave content={formContact.text} />
            </h4>
          </div>
          <FormFocusContact pageData={pageData} className="form-about-contact" />
        </div>
      </Rodal>
    </React.Fragment>
  )
}

export default ViewFocus
