import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/queryFocus.vi';
import ViewFocus from 'components/ViewFocus'

const FocusPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewFocus pageData={pageData} />
    </Layout>
  )
}

export default FocusPage
